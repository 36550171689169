import React from 'react';
import Horario from './Horario';

const ContentHorario = () => {
    return ( 
        <div className="content-horario">
            <div className="content-horario-agenda p-3 bg-white shadow-sm m-1 mt-3">
                
                <Horario/>
            </div>
        </div>
     );
}
 
export default ContentHorario;