import React from 'react';
import { Table } from 'react-bootstrap';
import * as moment from 'moment';
//Redux
import { useSelector } from 'react-redux';

const TablaDatosPension = ({pension}) => {

  const cuotasPensiones = pension;
  
  return ( 
    <div className="TablaDatosCuota">
      <Table striped responsive='md'>
        <thead>
            <tr>
              <th className='text-center'>#</th>
              <th className='text-center'>Concepto</th>
              <th className='text-center'>Fecha límite</th>
              <th className='text-center'>Inversión</th>
              <th className='text-center'>Penalidad (S/.)</th>
              <th className='text-center'>Descuento (S/.)</th>
              <th className='text-center'>Total inversión (S/.)</th>
              <th className='text-center'>Pago a cuenta (S/.)</th>
              <th className='text-center'>Saldo (S/.)</th>
              <th className='text-center'>Estado</th>
            </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-center">1</td>
            <td>{cuotasPensiones.concepto_pago.toUpperCase()}</td>
            <td className="text-center">
              {cuotasPensiones.fecha_limite ? (
                moment(cuotasPensiones.fecha_limite).format("DD MMM YYYY")
              ) : (
                <p className="mb-0 text-center">---</p>
              )}
            </td>
            <td className="text-center">{cuotasPensiones.monto_establecido}</td>
            <td className="text-center">{cuotasPensiones.importe_penalidad}</td>
            <td className="text-center">{cuotasPensiones.importe_descuento}</td>
            <td className="text-center">{cuotasPensiones.monto_pagado}</td>
            <td className="text-center">{parseFloat(cuotasPensiones.monto_pagado).toFixed(2) - parseFloat(cuotasPensiones.monto_saldo).toFixed(2)}</td>
            <td className='text-center'>{cuotasPensiones.monto_saldo}</td>
            <td>{cuotasPensiones.nombre_tp_estado_pago}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
 
export default TablaDatosPension;