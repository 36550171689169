import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import PodCastInfo from './PodCastInfo';


const ListaDePodCasts = ({podcasts}) => {


    const [busqueda,setBusqueda]=useState('')
    const handleChange = e => {
      setBusqueda(e.target.value)
    }

    const podcastFiltrado = podcasts ? podcasts.filter(
      podcast => {
        return (
          podcast
          .nombre_archivo
          .toLowerCase()
          .includes(busqueda.toLowerCase()) ||
          podcast
          .nombre_sesion
          .toLowerCase()
          .includes(busqueda.toLowerCase()) ||
          podcast
          .nombre_modulo
          .toLowerCase()
          .includes(busqueda.toLowerCase()) ||
          podcast
          .nombre_usuario
          .toLowerCase()
          .includes(busqueda.toLowerCase()) ||
          podcast
          .apellido_usuario
          .toLowerCase()
          .includes(busqueda.toLowerCase())
        )
      }
    ) : null

    

    return (
        <div className="col-12">
         <div className="row mb-4">
                <h4 className='col-md-8 font-20 text-uppercase'>
                 
                </h4>
                <div className="col-md-4 d-flex align-items-center">
                  <input className="form-control" value={busqueda} onChange={handleChange} style={{marginRight:'5px'}} placeholder="Búsqueda" />
                  <FontAwesomeIcon icon={faSearch} color={'#842eff'} size="lg"/>
                </div>
        </div>
        <div className="row">
          <div className="col-md-8 padding-bottom-movil">
          {   podcastFiltrado?.length>0 ? podcastFiltrado.map((podcast,i)=>(
              //  <Empleo datos={empleo} key={i} />
              <PodCastInfo datos={podcast} key={i} />
            )) : <div className="card col-12"><div className="card-body">No hay Podcasts recientes....</div></div>}
          </div>

        </div>
        </div>
    );
};

export default ListaDePodCasts;