import React from 'react';
import CertificadoContainer from './CertificadoContainer';
import ConstanciaEstudio from './ConstanciaContainer/ConstanciaContainer';

const Documentos = ({idProyecto}) => {
  return ( 
    <div className="documentos">
      <ConstanciaEstudio/>
      <CertificadoContainer idProyecto={idProyecto}/>
    </div>
   );
}
 
export default Documentos;