import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay, faVolumeMute } from '@fortawesome/free-solid-svg-icons';
import { Slider} from '@material-ui/core';

const AudioPlayerControls = ({onPlayPause, playing,played,onSeek,onSeekMouseDown,onSeekMouseUp,elapsedTime,totalDuration}) => {
    return (
        <div className="controlsWrapper">
            <div className="d-flex justify-content-between align-items-center">
                <button onClick={onPlayPause} className="btn btn-xs m-3" style={{background:'#842eff',color:'#fff'}}><FontAwesomeIcon icon={playing?faPause:faPlay} /></button>
                <Slider min={0} max={100} value={played*100} onChange={onSeek} onMouseDown={onSeekMouseDown} onChangeCommitted={onSeekMouseUp} style={{color:'#fff'}}/>
                <button className="btn" style={{maxWidth:'70px',background:'#842eff',color:'#fff'}}>{elapsedTime}/{totalDuration}</button>
            </div>
        </div>
    );
};

export default AudioPlayerControls;