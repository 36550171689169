import React, { useEffect, useState } from 'react';
import Sesion from '../../components/sesiones/Sesion';
import Layout from '../../components/Layout/Layout';
import { useParams , useHistory} from 'react-router-dom'
import '../../components/sesiones/sesiones.css'
import './SesionesModulos.scss'
//REDUX
import {connect} from 'react-redux';
import { mostrarSesiones, listarCurso } from "../../actions/cursosActions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward } from '@fortawesome/free-solid-svg-icons';
import { Avatar } from '@material-ui/core';
import ModalCVDocente from '../../components/ContenidoSesiones/CvDocente/ModalCVDocente';

const SesionesModulos = props => {
  const { idCurso } = useParams()
  const [detalleProfesor,setDetalleProfesor]=useState({})
   //State
   const { loading,listarCurso, sesiones,profesoresCurso,curso} = props
   const { mostrarSesiones} = props
    const history = useHistory()
     useEffect(()=> {
        mostrarSesiones(idCurso)
        listarCurso(idCurso);
     }, [])

 
     return (
      <Layout>
       
        {loading ? (
          <div className="constainer w-100 h-100 d-flex align-items-center" style={{minHeight: "500px", background: "rgba(0,0,0,.01)"}}>
            <div className="sk-folding-cube ">
              <div className="sk-cube1 sk-cube "></div>
              <div className="sk-cube2 sk-cube "></div>
              <div className="sk-cube4 sk-cube "></div>
              <div className="sk-cube3 sk-cube "></div>
            </div>
          </div>
        ) : (
          <>
        <div className="row">
          <div className="cabecera_modulos">
            <div className="container">
              <div className="row">
                  <div className="col-12 py-5" style={{color:'#fff'}}>
                    <h4>{curso?curso.NOMBRE_MODULO : 'cargando...'}</h4>
                    <p className="mb-2 text-uppercase font-weight-bold">Docentes:</p>
                      <ul className='col-md-6 d-flex flex-wrap' style={{listStyle:'none'}}>
                        {profesoresCurso.map((profesor, index) => (
                          <ProfesCurso key={index} profesor={profesor} setDataProfesor={setDetalleProfesor} />
                        ))}
                      </ul>
                  </div>
                  <ModalCVDocente dataDocente={detalleProfesor} />
              <button className="btn btn-danger" style={{position:'absolute', bottom:15, right:15}} onClick={()=> history.goBack()}><FontAwesomeIcon icon={faBackward}/> Regresar</button>
              
                </div>
            </div>        
          </div>
        </div>
        <div className="container mt-5">
        <div
          className="tab-content sesiones mt-5 bg-white border p-0 p-lg-3 "
          style={{ minHeight: "250px" }}
        >
         
            <h4 className="title-tabla mb-1 text-uppercase py-3 text-center text-white title-sesiones bg-primary">
              Sesiones asignadas
            </h4>
            <div className="content-table table-responsive">
            <table className="table table-striped table-hover t-seciones">
            
            <thead className="text-white text-uppercase bg-primary">
              <tr>
                <th scope="col" className="py-1 text-center">
                  Sesión
                </th>
                <th scope="col" className="py-1 th-tema text-center">
                  Tema
                </th>
                <th scope="col" className="py-1 th-fecha text-center">
                  Fecha
                </th>
                <th scope="col" className="py-1 text-right" style={{minWidth:'100px'}}>
                  
                </th>
              </tr>
            </thead>
            <tbody className="text-secondary">
              {sesiones.length > 0 ? (
                sesiones.map((sesion, index) => (
                  <Sesion
                    key={index}
                    sesion={sesion}
                    index={index}
                  />
                ))
              ) : (
                <tr>
                  <td
                    colSpan="5"
                    className="text-danger text-center text-uppercase"
                  >
                    No hay registros de sesiones
                  </td>
                </tr>
              )}
            </tbody>
          </table>
           
          </div>
        </div>
        </div>
        </>
        )}
        
      </Layout>
    );
}
 
const mapStateToProps = state => ({
  sesiones: state.sesiones.sesiones,
  loading: state.sesiones.loading,
  usuario: state.usuario.usuario,
  profesoresCurso: state.cursos.profesoresCurso,
  curso:state.cursos.curso,
});

const mapDispatchToProps = {
  listarCurso,
  mostrarSesiones
}
const SesionesModulosComponent = connect(mapStateToProps, mapDispatchToProps)(SesionesModulos);
export default React.memo(SesionesModulosComponent)

/********* */
const ProfesCurso = ({profesor, setDataProfesor}) => {
    return <li className='col-6' style={{padding:'4px'}}> <span  target="_blank"
          onClick={() => setDataProfesor(profesor)}
          data-toggle="modal"
          data-target="#modal-cvd"
          title='Ver trayectoria' style={{cursor:'pointer'}}> {profesor.img_usuario===null? <img
               
    alt=""
    src='/images/user.png'
   style={{width:'30px', height:'30px', borderRadius:'50%'}}
  /> :
<img
    alt=""
    src={`${process.env.REACT_APP_API_URL}/${profesor.img_usuario}`}
    style={{width:'30px', height:'30px', borderRadius:'50%'}}
  />} <span style={{fontSize:'14px'}}> {`${profesor.nombre_usuario} ${profesor.apellido_usuario}`}</span></span></li>
}
