import React from 'react'

//Redux
import {connect} from 'react-redux'

const InfoProyecto = ({proyecto, modulos, calificaciones}) => {
    return (
      <React.Fragment>
        <div className="title-proyecto content-header title font-20 mb-3 header-vista-content border-header-primary-b">
          <h4 className="pl-2 font-20">{proyecto.nombre_proyecto}</h4>
        </div>
        <div className="content-body-proyecto">
          <div className="resumen">
            <div className="resumen-title text-white p-2">
              <h4 className="text-uppercase mb-0">resumen general</h4>
            </div>

            <div className="row p-3">
              <div className="col-md-4">
                <div className="content-info">
                  <div className="info">
                    <p className="font-weight-bold text-label">Sede</p>
                    <p className="ml-2">{proyecto.sede}</p>
                  </div>
                  <div className="info mt-2">
                    <p className="font-weight-bold text-label">Duración</p>
                    <p className="ml-2 text-lowercase">
                      {proyecto.cantidad_lapso} {proyecto.nombre_tp_lapso}
                    </p>
                  </div>
                  <div className="info mt-2">
                    <p className="font-weight-bold text-label">Grupo WhatsApp</p>
                    <p className="ml-2">{proyecto.enlace_grupo!==null ? <a className="btn btn-danger" href={proyecto.enlace_grupo} target="_blank">Únete aquí</a> : 'Sin Enlace'}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="content-info">
                  <div className="info">
                    <p className="font-weight-bold text-label">Módulos</p>
                    <p className="ml-2">{modulos.length}</p>
                  </div>
                  <div className="info mt-2">
                    <p className="font-weight-bold text-label">
                      Cantidad de créditos
                    </p>
                    <p className="ml-2">{proyecto.creditos}</p>
                  </div>
                  <div className="info mt-2">
                    <p className="font-weight-bold text-label">
                      Horas académicas
                    </p>
                    <p className="ml-2">{proyecto.hrs_academicas}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="content-info">
                <div className="info">
                    <p className="font-weight-bold text-label">
                      Horas lectivas
                    </p>
                    <p className="ml-2">{proyecto.hrs_lectivas}</p>
                  </div>
                  <div className="info">
                    <p className="font-weight-bold text-label">
                      Promedio ponderado
                    </p>
                    <p className="ml-2">{parseFloat(calificaciones?.promedio).toFixed(0)}</p>
                  </div>
                  <div className="info mt-2">
                    <p className="font-weight-bold text-label">
                      Mérito - Orden
                    </p>
                    <p className="ml-2"> {calificaciones.orden? calificaciones.orden : '---'} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}

const mapStateToProps = state => ({
    modulos: state.cursos.modulos,
    calificaciones: state.cursos.calificaciones
})
 
export default connect(mapStateToProps, {})(InfoProyecto);