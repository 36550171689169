import React from 'react';
import Layout from '../../components/Layout/Layout';
import { condiciones } from '../../utils/reglasnormas/valuesCondiciones';
import { valuesEducacion } from '../../utils/reglasnormas/valuesEducacion'
import { normasAcademicas } from '../../utils/reglasnormas/valuesNormasAcademicas';
import AcordeonReglas from '../../components/plugins/AcordeonReglas';
import TitlePagina from '../../components/TitlePagina';
import './ReglasNormas.scss';

const ReglasNormas = () => {
  return ( 
    <Layout>
      <div className="container reglas-normas mt-5">
        <div className="content-preguntas bg-white p-3 shadow-sm">
          <TitlePagina clases='primary lg'>
            información para discentes
          </TitlePagina>
          <div className="sub-title">
            <h5>Educación</h5>
          </div>

          <div id="accordionExample-0" className="accordion">
            {valuesEducacion.map((item, key) => (
              <AcordeonReglas
                key={key}
                label={item.label}
                contenido={item.content}
                id={`edu-${key}`}
                posicion={key}
                target={'#accordionExample-0'}
                show={false}
              />
            ))}
          </div>
          <div
            className="sub-title">
            <h5>Normas académicas</h5>
          </div>
          <div id="accordionExample-1" className="accordion">
         <div style={{padding:'15px', textAlign:'justify',fontWeight:'450'}}><p>De conformidad con la Ley Universitaria N° 30220, ratificado con la Res. N° 141 – 2006 SERVIR – PE y disposiciones complementarias del Ministerio de Educación. </p>
          <p>Normas, para asegurar el mayor aprovechamiento y satisfacción en los servicios brindados.
          <br/>A través de un sistema permanente de retroalimentación (medición, evaluación y análisis) que incluye a todos los agentes del servicio iremos perfeccionando nuestras normas.
          </p> 
          </div>  
            {normasAcademicas.map((item, key) => (
              <AcordeonReglas
                key={key}
                label={item.label}
                contenido={item.content}
                id={`aca-${key}`}
                target={'#accordionExample-1'}
                posicion={key}
                show={false}
              />
            ))}
          </div>
     
        </div>
      </div>
    </Layout>
   );
}
 
export default ReglasNormas;