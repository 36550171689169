import { alertaTypes } from './types';

export const showAlerta = () => {
  return dispatch => {
    dispatch({
      type: alertaTypes.SHOW_ALERTA
    })
  }
}
export const showMenu = () => {
  return dispatch => {
    dispatch({
      type: alertaTypes.SHOW_MENU
    })
  }
}
export const showDetalle = () => {
  return dispatch => {
    dispatch({
      type: alertaTypes.SHOW_DETALLE_EMPLEO
    })
  }
}