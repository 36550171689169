import React from 'react'
import PieChart from '../../plugins/Chart/PieChart' 
import { Link } from 'react-router-dom';
import shortid from 'shortid';
import * as moment from 'moment'

const Modulo = ({modulo}) => {


    return (
      <div className="modulo bg-white shadow-sm p-3 mt-3">
        <div
          className="modulo-header text-white p-2"
        >
          <h4 className="pl-2 mb-0 text-uppercase">{modulo.nombre_modulo}</h4>
        </div>
        <div className="container-fluid mt-3">
          <div className="row">
            <div className="col-md-8">
              <div className="detalles-modulo">
              <div className="__detalles-modulo">
                  <p
                    className="mb-0 label font-weight-bold"
                  >
                    Duración
                  </p>
                  <ul className="pl-4">
                    <li>
                        Del { moment(modulo.fecha_inicio_modulo).format('DD/MM/YYYY')} al { moment(modulo.fecha_fin_modulo).format('DD/MM/YYYY') }
                    </li>
                  </ul>
                </div>
                <div className="__detalles-modulo mt-2">
                  <p
                    className="mb-0 label font-weight-bold"
                  >
                    Docente
                  </p>
                  <ul className="pl-4">
                    {modulo.docentes.map((docente) => (
                      <li
                        key={docente.id_docente}
                      >{`${docente.nombre_docente} ${docente.apellido_docente}`}</li>
                    ))}
                  </ul>
                </div>
                <div className="__detalles-modulo mt-2">
                  <p
                    className="font-weight-bold label"
                  >
                    Horario
                  </p>
                  <ul className="mb-0 pl-4">
                    {modulo.id_proyecto==='53' ? modulo.horario.map((turno,i) => (
                      <li key={shortid.generate()}>
                        <p style={{ textTransform: "capitalize" }}>
                          {turno.nombreTurno}: {turno.turno} {i===0 ? ' (Sábado)':' (Viernes)'}
                        </p>
                      </li>
                    )) : modulo.id_modulo==='79' && modulo.id_proyecto==='28' ? <li>
                        <p style={{ textTransform: "capitalize" }}>
                          Turno Tarde: 15:00:00 - 19:00:00
                        </p>
                      </li> : modulo.horario.map((turno) => (
                      <li key={shortid.generate()}>
                        <p style={{ textTransform: "capitalize" }}>
                          {turno.nombreTurno}: {turno.turno}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="__detalles-modulo mt-2">
                  <p
                    className="font-weight-bold label"
                  >
                    Sesiones: <span>{modulo.cant_Sesiones}</span>
                  </p>
                </div>
                <div className="__detalles-modulo mt-2">
                  <p
                    className="font-weight-bold label"
                  >
                    Horas Lectivas totales: <span>{modulo.horas_modulo}</span>
                  </p>
                </div>
              </div>
              <div className="boton-sesiones mt-3">
                <Link
                  to={`/capacitaciones/${modulo.id_modulo}/sesiones`}
                  className=" btn btn-primary"
                  style={{fontSize: "12px" }}
                >
                  Ver sesiones
                </Link>
              </div>
            </div>
            <div className="col-md-4">
              <div className="detalles-asistencia">
                <div className="grafico-asistencia">
                  <p
                    className="font-weight-bold label text-center mb-3"
                  >
                    Asistencia presencial
                  </p>
                  <div className="container">
                    <PieChart
                      asistencia={parseInt(modulo.cant_SeAsistidas)}
                      inasistencia={parseInt(modulo.cant_SeInasistencia)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
 
export default Modulo;