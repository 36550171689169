import React, { useState } from 'react';
import Layout from "../../components/Layout/Layout";
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {verExamenResultado} from '../../actions/cursosActions'
import { useDispatch, useSelector } from 'react-redux';
import ListarPreguntasExamen from './ComponentsResultados/ListarPreguntasExamen';
import { Card, Alert} from "react-bootstrap";

const ResultadoExamen = () => {

    const {examen} = useSelector(state=> state.cursos);
    const {usuario} = useSelector(state=> state.usuario);
    const dispatch = useDispatch();
    const [examen_res, setExamen] = useState(null);
    const {idSesion,idCurso} = useParams();
    useEffect(()=>{
        async function fetchData() {
            // You can await here
            const response = await dispatch(verExamenResultado(examen.id_examen,usuario.id_estudiante));
            //console.log(response)
            setExamen(response);
            // ...
          }
          fetchData();
    },[])

    return (

    <Layout>
        <div className="container content mt-5">
      
        { examen_res ? examen_res.examen.preguntas.length>0 ?
          <div style={{maxWidth:"700px", margin:"auto"}}>
{     //       <div style={{height:'150px', background: "url('https://intranet.inedi.edu.pe/images/cabecera_examen.png') center center no-repeat", backgroundSize:"cover", marginBottom:"10px", borderRadius:"8px"}}></div>
}            <Card style={{borderTop:'#27314A 5px solid', marginBottom:'15px'}}>
            
                <Card.Body>
                <h1>{examen_res ? 'Resultados: '+ examen_res.examen.preguntas[0].pregunta.nombre_examen : ''}</h1>
                <p style={{fontSize:'18px'}}>{examen_res ? examen_res.examen.preguntas[0].pregunta.descripcion_examen: ''}</p>

                
                </Card.Body>
              
            </Card>
            <Link className="btn btn-primary" to={'/capacitaciones/'+idCurso+'/sesiones'} style={{marginBottom:'10px'}}> Volver a Sesiones </Link>
            <form id="examen_res"> 

            { examen_res && <ListarPreguntasExamen preguntas={examen_res.examen.preguntas} respuestas={examen_res.examen.respuestas.respuestas_obtenidas} />}

            </form>
           
          </div>
       :  <Alert variant="info">
                      No hay resultados para esta sesión.  <Link className="btn btn-primary" to={'/capacitaciones/'+idCurso+'/sesiones'} style={{marginBottom:'10px'}}> Volver a Sesiones </Link>
                        </Alert> : <div className="w-100 h-100 d-flex align-items-center" style={{ minHeight: "500px", background: "rgba(0,0,0,.01)" }}>

<div className="sk-folding-cube ">
  <div className="sk-cube1 sk-cube "></div>
  <div className="sk-cube2 sk-cube "></div>
  <div className="sk-cube4 sk-cube "></div>
  <div className="sk-cube3 sk-cube "></div>
</div>
</div>}
         
        </div>

       
    </Layout>

    );
};

export default ResultadoExamen;