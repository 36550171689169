import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import axios from 'axios';
import './Toast.scss';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../actions/toastActions';
import { CSSTransition,TransitionGroup } from 'react-transition-group';

const ToastMessage = () => {
  const [post, setPost] = useState(null);

  const show = useSelector(state => state.toast.show)
  const dispatch = useDispatch();

  const getFirtsPost = async () => {
    const posts = await axios.get('https://inedi.edu.pe/wp-json/wp/v2/posts?_embed&per_page=1');
    setPost(posts.data[0]);
  }

  useEffect(() => {
    if (show) {
      getFirtsPost();
    }
  }, [show])


  if (!post) return null; 

  return (
    <TransitionGroup
      component='div'
      className='toast-content'
    >
      <CSSTransition key={'show'} timeout={{enter: 500, exit: 500}} classNames="toast-content">
        <Toast
          onClose={() => dispatch(showToast())}
          show={show && post ? true : false}
          animation={true}
          className='toast-animation-content'
        >
          <Toast.Header>
            <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
            <p className="mr-auto">INEDI Posgrados&#174;</p>
          </Toast.Header>
          <Toast.Body>
            <div className='bg-white p-3 d-flex justify-content-between'>
              <img src={`${
post?._embedded['wp:featuredmedia']
? post?._embedded['wp:featuredmedia'][0]?.source_url
: ''
}`} className='img img-fluid' alt=""/>
              <p>
                {post.title.rendered}
              </p>
            </div>
            <div className='text-right mt-3'>
              <a href={`${post.link}`} target='_blank' rel="noopener noreferrer" className='btn btn-danger'>
                Saber más
              </a>
            </div>
          </Toast.Body>
        </Toast>
      </CSSTransition>
    </TransitionGroup>
   );
}
 
export default ToastMessage;