import React, { useEffect, useState } from 'react';
import Layout from "../../components/Layout/Layout";
import { Link, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import * as moment from 'moment'
import { Card, Alert} from "react-bootstrap";
import { guardarHistorialVideo, traerVideo } from '../../actions/cursosActions';
import { faBackward, faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NuevoComentario from './NuevoComentario';
import Comentarios from './Comentarios';
import ReactHtmlParser from 'react-html-parser';
import Video from './Video';
const Videos = (props) => {

    const {video_actual} = props
    const history = useHistory()
    const dispatch = useDispatch()
    const {idSesion,video} = useParams()
    const[datosVideo,setDatosVideo] = useState(null)
    const [loading, setLoading] = useState(true)
    const usuario = useSelector(state=> state.usuario.usuario);
    const datosFetch = {
        video:video,
        sesion:idSesion,
        id_usuario:usuario?usuario.id_usuario:null
    }
    useEffect(()=>{
    const fetchData = async () =>{

        const rpta = await(dispatch(traerVideo(datosFetch)))

        if(rpta)
        {   
            setLoading(false)
            dispatch(guardarHistorialVideo(datosFetch))
           // setDatosVideo(rpta[0])
        }

    }

    fetchData()
   
    },[])


    useEffect(()=>{
        if(video_actual)
        {
            setDatosVideo(video_actual[0])
            
        }
    },[video_actual])


    return (

    <Layout>
   { !loading ? datosVideo ?
   <div className="container mt-5">
            <div className="d-flex justify-content-end mb-2">
                     <button className="btn btn-danger" onClick={()=> history.goBack()}><FontAwesomeIcon icon={faBackward}/> Regresar</button>
            </div> 
        <div className="row bg-white shadow-sm">
      
            <div className="col-md-8 p-3 video-individual">
            <h3>{datosVideo ? datosVideo.nombre_archivo : ''}</h3>
            {ReactHtmlParser(datosVideo ? datosVideo.ruta_archivo : '')}
            </div>
            <div className="col-md-4 p-3" style={{maxHeight:'400px', overflowY:'scroll'}}>
                 {  datosVideo ? datosVideo.videos.length!==0 ? datosVideo.videos.map((video, i) => (
                    <Video key={i} video={video}/>
                  )) : 'No hay comentarios recientes...': 'No hay comentarios recientes...' }   
            </div>
        </div>
        <div className="row p-3 bg-white shadow-sm mt-2">
        Deja tus comentarios desde aquí  <NuevoComentario id_video={datosVideo.id_archivo_sesion} id_sesion={idSesion} />
        {
        
    //  <Comentarios comentarios={temaF.comentarios} />
        }
        </div>  
        <div className="row p-3 bg-white shadow-sm mt-2">
        {
         <Comentarios comentarios={datosVideo.comentarios} />
        }
        </div> 
    </div> :'No hay información....':<div className="w-100 h-100 d-flex align-items-center" style={{ minHeight: "500px", background: "rgba(0,0,0,.01)" }}>

<div className="sk-folding-cube ">
  <div className="sk-cube1 sk-cube "></div>
  <div className="sk-cube2 sk-cube "></div>
  <div className="sk-cube4 sk-cube "></div>
  <div className="sk-cube3 sk-cube "></div>
</div>
</div> }
 
    </Layout>

    );
};
const mapStateToProps = state => ({
    video_actual : state.foro.video_actual
})
export default connect(mapStateToProps, {})(Videos);