/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { cantidadMensajesNoVistos } from "../../actions/MensajesActions";
import {
	consultarNotificaciones,
	consultarPuntos,
} from "../../actions/usuarioActions";
import { Link } from "react-router-dom";
//REDUX
import { connect, useDispatch } from "react-redux";

import "./Header.scss";
import "../Menus/BasicMenu/SlideMenu.scss";
import AccionesAvatar from "./AccionesAvatar";
import { showMenu } from "../../actions/alertaActions";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = ({
	usuario,
	inedirank,
	notificaciones,
	cantidadMensajesNoVistos,
	consultarPuntos,
	consultarNotificaciones,
}) => {
	useEffect(() => {
		cantidadMensajesNoVistos(usuario.id_usuario);
		consultarPuntos(usuario.id_usuario);
		consultarNotificaciones(usuario.id_usuario);
	}, []);
	const dispatch = useDispatch();
	const { nombre_usuario, apellido_usuario, img_usuario } = usuario;
	const handleClose = () => {
		dispatch(showMenu());
	};

	return (
		<React.Fragment>
			<nav className="navbar fixed-top Header navbar-expand-lg">
				<div className="container-fluid d-flex justify-content-between pl-1 pr-1 pl-lg-5 pr-lg-5">
					{}
					<div className="d-flex justify-content-between">
						<a
							className="menuButtonNav"
							onClick={() => handleClose()}
						>
							<FontAwesomeIcon icon={faBars} />
						</a>
						<picture className="logo d-none d-sm-flex align-items-center ">
							<Link to="/capacitaciones">
								<img
									className="img img-fluid"
									style={{ marginLeft: "20px" }}
									src="/images/gestora_blanco.png"
									alt="Logo Gestora"
								/>
							</Link>
						</picture>
					</div>
					{usuario !== undefined ? (
						usuario.length > 0 ||
						Object.keys(usuario).length > 0 ? (
							<AccionesAvatar
								nombre={nombre_usuario}
								apellido={apellido_usuario}
								imagenUsuario={`${img_usuario}`}
								puntos={inedirank}
								notificaciones={notificaciones}
							/>
						) : null
					) : null}
				</div>
			</nav>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => ({
	usuario: state.usuario.usuario,
	inedirank: state.usuario.inediRank,
	notificaciones: state.usuario.notificaciones,
});

export default connect(mapStateToProps, {
	cantidadMensajesNoVistos,
	consultarPuntos,
	consultarNotificaciones,
})(Header);
