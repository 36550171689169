import React, { useState, useEffect } from "react";
import ModalVideo from "./videos/ModalVideo";
import * as moment from "moment";
import Recurso from "./Recurso";
import TablaTareas from "./Tareas/TablaTareas";
import Video from "./videos/Video";
import Layout from "../Layout/Layout";
import shortid from "shortid";
import LinkVivo from "./linkVivo/LinkVivo";
import "./contenidoSesiones.css";
import { Link, useParams, useHistory } from "react-router-dom";
import {
	faBackward,
	faChevronCircleRight,
	faPaperclip,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Redux
import { connect } from "react-redux";
import {
	listarTareas,
	listarRecursos,
	listarCurso,
	listarSesion,
} from "../../actions/cursosActions";
import Examen from "./Examen/Examen";
import ModalRecurso from "./ModalRecurso";
import ModalCVDocente from "./CvDocente/ModalCVDocente";

const ContenidoSesiones = (props) => {
	const { idSesion, idCurso } = useParams();
	//State
	const {
		sesion,
		videos,
		linkVivo,
		detalleNivel,
		curso,
		recursos,
		podcasts,
	} = props;
	//Funciones
	const { listarSesion, listarRecursos, listarTareas, listarCurso } = props;

	const [urlMiga, setUrlMiga] = useState([]);
	const [cargaTermina, setCargaTermina] = useState(false);
	const history = useHistory();
	//Ver que iframe se mostrara
	const [dataVideo, setDataVideo] = useState({});
	const [dataPodcast, setDataPodcast] = useState({});

	useEffect(() => {
		listarCurso(idCurso);

		cargaRecurso();
		listarSesion(idSesion);
	}, []);

	const cargaRecurso = async () => {
		if ((await listarRecursos(idSesion)).mensaje) {
			setCargaTermina(true);
		}
	};

	//Para las rutas
	useEffect(() => {
		if (curso && sesion) {
			dataMiga(curso);
		}
	}, [curso, sesion]);

	useEffect(() => {
		if (detalleNivel.length > 0) {
			listarTareas(idSesion, detalleNivel[0].id_estudiante);
		}
	}, [detalleNivel.length]);

	//PARA LAS RUTAS DE MIGAS DE PAN
	const dataMiga = (curso) => {
		let dataRuta = [
			{ title: <i className="material-icons">home</i>, url: "/inicio" },
			{
				title: `${curso.nombre_curso}`,
				url: `/cursos/${curso.id_curso}/sesiones`,
			},
			{
				title: (
					<span className="text-capitalize">
						{sesion.nombre_sesion}
					</span>
				),
				url: ``,
			},
		];

		setUrlMiga(dataRuta);
	};

	if (cargaTermina === false)
		return (
			<Layout>
				<div
					className="w-100 h-100 d-flex align-items-center"
					style={{
						minHeight: "500px",
						background: "rgba(0,0,0,.01)",
					}}
				>
					<div className="sk-folding-cube ">
						<div className="sk-cube1 sk-cube "></div>
						<div className="sk-cube2 sk-cube "></div>
						<div className="sk-cube4 sk-cube "></div>
						<div className="sk-cube3 sk-cube "></div>
					</div>
				</div>
			</Layout>
		);

	return (
		<Layout>
			<div className="row">
				<div className="cabecera_modulos">
					<div className="container">
						<div className="row">
							<div
								className="col-md-6 py-5"
								style={{ color: "#fff" }}
							>
								<h6>
									{" "}
									{sesion !== undefined
										? sesion.nombre_sesion
										: null}{" "}
								</h6>
								<p className="font-weight-bold text-horario">
									Horario:
								</p>
								<p className="mb-0 text-capitalize fecha-sesion">
									{sesion !== undefined
										? moment(sesion.fecha_sesion).format(
												"DD MMM YYYY"
										  )
										: null}
									{<br />}
								</p>
								<ul
									className="mb-0 dia-horario "
									style={{ listStyle: "none" }}
								>
									{idSesion === "201" ? (
										<>
											<li>
												<p
													style={{
														textTransform:
															"capitalize",
													}}
													className="text-horario"
												>
													Mañana: 9:00:00 - 11:00:00
												</p>
											</li>
										</>
									) : idSesion === "384" ? (
										<>
											<li>
												<p
													style={{
														textTransform:
															"capitalize",
													}}
													className="text-horario"
												>
													Mañana: 9:00:00 - 12:00:00
												</p>
											</li>
										</>
									) : idSesion === "239" ? (
										<>
											<li>
												<p
													style={{
														textTransform:
															"capitalize",
													}}
													className="text-horario"
												>
													Tarde: 15:00:00 - 19:00:00
												</p>
											</li>
										</>
									) : sesion !== undefined ? (
										sesion.horario ? (
											sesion.horario.map((turno) => (
												<li key={shortid.generate()}>
													<p
														style={{
															textTransform:
																"capitalize",
														}}
														className="text-horario"
													>
														{turno.nombreTurno}:{" "}
														{turno.turno}
													</p>
												</li>
											))
										) : null
									) : null}
								</ul>
							</div>
							<div
								className="col-md-6 py-5"
								style={{ color: "#fff" }}
							>
								{sesion.jurado ? (
									<>
										{" "}
										<p className="mb-0 mt-3 text-profesor text-uppercase">
											Jurado:
										</p>
										{sesion !== undefined
											? sesion.docentes_invitados !==
													undefined &&
											  sesion.docentes_invitados.length >
													0
												? sesion.docentes_invitados.map(
														(docente_i, i) => (
															<p
																className="text-profesor text-uppercase"
																key={i}
															>
																{" "}
																{docente_i.nombre_usuario +
																	" " +
																	docente_i.apellido_usuario}{" "}
															</p>
														)
												  )
												: "Pendiente"
											: null}
									</>
								) : sesion.clausura ? (
									<>
										{" "}
										<p className="mb-0 mt-3 text-profesor text-uppercase">
											Mesa de Honor:
										</p>
										{sesion !== undefined
											? sesion.docentes_invitados !==
													undefined &&
											  sesion.docentes_invitados.length >
													0
												? sesion.docentes_invitados.map(
														(docente_i, i) => (
															<p
																className="text-profesor text-uppercase"
																key={i}
															>
																{" "}
																{docente_i.nombre_usuario +
																	" " +
																	docente_i.apellido_usuario}{" "}
															</p>
														)
												  )
												: "Pendiente"
											: null}
									</>
								) : (
									<div>
										{" "}
										<p className="mb-0 mt-3 text-profesor text-uppercase">
											docente: <br />{" "}
											{sesion !== undefined ? (
												sesion.docente !== undefined &&
												sesion.docente.length > 0 ? (
													<>
														{" "}
														{sesion.docente[0]
															.img_usuario ===
														null ? (
															<img
																alt=""
																src="/images/user.png"
																style={{
																	width: "30px",
																	height: "30px",
																	borderRadius:
																		"50%",
																}}
															/>
														) : (
															<img
																alt=""
																src={`${process.env.REACT_APP_API_URL}/${sesion.docente[0].img_usuario}`}
																style={{
																	width: "30px",
																	height: "30px",
																	borderRadius:
																		"50%",
																}}
															/>
														)}{" "}
														<span
															target="_blank"
															data-toggle="modal"
															data-target="#modal-cvd"
															title="Ver trayectoria"
															style={{
																cursor: "pointer",
															}}
														>
															{" "}
															{sesion.docente[0]
																.nombre_usuario +
																" " +
																sesion
																	.docente[0]
																	.apellido_usuario}{" "}
														</span>{" "}
													</>
												) : (
													"Pendiente"
												)
											) : null}
										</p>{" "}
										{sesion.docente !== undefined &&
										sesion.docente.length > 0 ? (
											<>
												<ModalCVDocente
													dataDocente={
														sesion.docente[0]
													}
												/>{" "}
											</>
										) : null}{" "}
									</div>
								)}
							</div>
							<button
								className="btn btn-danger"
								style={{
									position: "absolute",
									bottom: 15,
									right: 15,
								}}
								onClick={() => history.goBack()}
							>
								<FontAwesomeIcon icon={faBackward} /> Regresar
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="container mt-5">
				{/* {urlMiga.length !== 0 ? <Breadcrumb data={urlMiga} /> : null} */}
				<div
					className="container-fluid acordeon-recursos mt-4 px-0"
					style={{ minHeight: "300px" }}
				>
					<div className="row">
						<div className="col-lg-12">
							{/* Accordion 0*/}
							<div id="accordionExample" className="accordion">
								<div className={`card mensajes-info`}>
									<div
										id="headingPodcast"
										className="card-header shadow-sm border-0 bg-primary rounded-lg py-2"
									>
										<h6 className="mb-0 font-weight-bold">
											<a
												href="#"
												data-toggle="collapse"
												data-target="#collapsePodcast"
												aria-expanded="true"
												aria-controls="collapsePodcast"
												className={`d-block position-relative font-weight-bold text-white collapsible-link py-2`}
											>
												El podcast
											</a>
										</h6>
									</div>
									<div
										id="collapsePodcast"
										aria-labelledby="headingPodcast"
										data-parent="#accordionExample"
										className={`collapse py-1 rounded border-bottom`}
									>
										{podcasts.length > 0 ? (
											<>
												<div className="alert alert-dismissible alert-light mensajes-info">
													Todos los días lunes subimos
													recursos nuevos para ti.
													¡Disfrútalos!
												</div>
												{podcasts.map((recurso) => (
													<Recurso
														key={
															recurso.id_archivo_sesion
														}
														recurso={recurso}
														setDataPodcast={
															setDataPodcast
														}
													/>
												))}
											</>
										) : (
											<div className="alert alert-dismissible alert-light mensajes-info">
												Todos los días lunes subimos
												recursos nuevos para ti.
												¡Disfrútalos!
											</div>
										)}
									</div>
								</div>
								<ModalRecurso dataRecurso={dataPodcast} />
								<div className={`card mensajes-info`}>
									<div
										id="headingClase"
										className="card-header bg-primary shadow-sm border-0 rounded py-2"
									>
										<h6 className="mb-0 font-weight-bold">
											<a
												href="#"
												data-toggle="collapse"
												data-target="#collapseClase"
												aria-expanded="false"
												aria-controls="collapseClase"
												className="d-block position-relative collapsed font-weight-bold text-white collapsible-link py-2"
											>
												La clase
											</a>
										</h6>
									</div>
									<div
										id="collapseClase"
										aria-labelledby="headingClase"
										data-parent="#accordionExample"
										className={`collapse rounded border-bottom`}
										style={{ padding: "25px 50px" }}
									>
										<div
											id="acordionBeta"
											className="accordion"
										>
											<div
												className={`card ${
													idCurso === "25"
														? "d-none"
														: ""
												} mensajes-info`}
											>
												<div
													id="headingThree"
													className="card-header bg-primary shadow-sm border-0 rounded py-2"
												>
													<h6 className="mb-0 font-weight-bold">
														<a
															href="#"
															data-toggle="collapse"
															data-target="#collapseCero"
															aria-expanded="false"
															aria-controls="collapseCero"
															className="d-block position-relative collapsed font-weight-bold text-white collapsible-link py-2"
														>
															{idCurso === "26"
																? "Exámenes"
																: "Online"}
														</a>
													</h6>
												</div>
												<div
													id="collapseCero"
													aria-labelledby="headingThree"
													data-parent="#acordionBeta"
													className={`collapse ${
														idCurso !== "25"
															? "show"
															: ""
													} rounded border-bottom`}
												>
													{linkVivo !== undefined ? (
														linkVivo.length > 0 ? (
															<>
																<div className="alert alert-dismissible alert-light mensajes-info">
																	Todos los
																	días lunes
																	encontrarás
																	los enlaces
																	a las
																	sesiones online.
																</div>
																{linkVivo.map(
																	(vivo) => (
																		<LinkVivo
																			key={
																				vivo.id_archivo_sesion
																			}
																			vivo={
																				vivo
																			}
																		/>
																	)
																)}
															</>
														) : (
															<div className="alert alert-dismissible alert-light mensajes-info">
																Todos los días
																lunes
																encontrarás los
																enlaces a las
																sesiones online.
															</div>
														)
													) : null}
												</div>
											</div>

											{/**Acrodeon 1 */}
											<div
												className={`card ${
													idCurso === "25" ||
													idCurso === "26" ||
													sesion.clausura
														? "d-none"
														: ""
												}`}
											>
												<div
													id="headingThree"
													className="card-header bg-primary shadow-sm border-0 rounded py-2"
												>
													<h6 className="mb-0 font-weight-bold">
														<a
															href="#"
															data-toggle="collapse"
															data-target="#collapseOne"
															aria-expanded="false"
															aria-controls="collapseOne"
															className={`d-block font-weight-bold position-relative collapsed text-white collapsible-link py-2`}
														>
															Offline
														</a>
													</h6>
												</div>
												<div
													id="collapseOne"
													aria-labelledby="headingThree"
													data-parent="#acordionBeta"
													className="collapse rounded border-bottom"
												>
													{/*<div className="alert alert-dismissible alert-light">

                      No hay archivos para mostrar
                    </div>*/}
													{videos !== undefined ? (
														videos.length > 0 ? (
															<>
																<div className="alert alert-dismissible alert-light mensajes-info">
																	Todos los
																	días lunes
																	podrás
																	disfrutar de
																	las sesiones
																	offline.
																</div>
																{videos.map(
																	(
																		video,
																		index
																	) => (
																		<Video
																			key={
																				index
																			}
																			video={
																				video
																			}
																			setDataVideo={
																				setDataVideo
																			}
																		/>
																	)
																)}
															</>
														) : (
															<div className="alert alert-dismissible alert-light mensajes-info">
																Todos los días
																lunes podrás
																disfrutar de las
																sesiones offline.
															</div>
														)
													) : null}
												</div>
											</div>
											<ModalVideo dataVideo={dataVideo} />
										</div>
									</div>
								</div>

								{/* Accordion item 2 */}
								<div
									className={`card mb-1 shadow-sm rounded-lg ${
										idCurso === "26" || sesion.clausura
											? "d-none"
											: ""
									}`}
								>
									<div
										id="headingOne"
										className="card-header shadow-sm border-0 bg-primary rounded-lg py-2"
									>
										<h6 className="mb-0 font-weight-bold">
											<a
												href="#"
												data-toggle="collapse"
												data-target="#collapseTwo"
												aria-expanded="true"
												aria-controls="collapseTwo"
												className={`d-block position-relative font-weight-bold text-white collapsible-link py-2`}
											>
												Los materiales
											</a>
										</h6>
									</div>
									<div
										id="collapseTwo"
										aria-labelledby="headingOne"
										data-parent="#accordionExample"
										className={`collapse ${
											idCurso === "25" ? "show" : ""
										} py-1 rounded border-bottom`}
									>
										{recursos.length > 0 ? (
											<>
												<div className="alert alert-dismissible alert-light mensajes-info">
													Todos los días lunes subimos
													recursos nuevos para ti.
													¡Disfrútalos!
												</div>
												{recursos.map((recurso) => (
													<Recurso
														key={
															recurso.id_archivo_sesion
														}
														recurso={recurso}
													/>
												))}
											</>
										) : (
											<div className="alert alert-dismissible alert-light mensajes-info">
												Todos los días lunes subimos
												recursos nuevos para ti.
												¡Disfrútalos!
											</div>
										)}
									</div>
								</div>
								{/* Accordion item 3 */}
								<div
									className={`card  mt-3 ${
										idCurso === "25" ||
										idCurso === "26" ||
										sesion.clausura
											? "d-none"
											: ""
									}`}
								>
									<div
										id="headingTwo"
										className="card-header bg-primary shadow-sm border-0 rounded py-2"
									>
										<h6 className="mb-0 font-weight-bold">
											<a
												href="#"
												data-toggle="collapse"
												data-target="#collapseThree"
												aria-expanded="false"
												aria-controls="collapseThree"
												className="d-block position-relative font-weight-bold collapsed text-white collapsible-link py-2"
											>
												La actividad
											</a>
										</h6>
									</div>
									<div
										id="collapseThree"
										aria-labelledby="headingTwo"
										data-parent="#accordionExample"
										className="collapse rounded border-bottom"
									>
										<TablaTareas
											idSesion={idSesion}
											idCurso={idCurso}
										/>
									</div>
								</div>
								{/* Accordion item 3 */}
								<div
									className={`card  mt-3 ${
										sesion.clausura ? "d-none" : ""
									}`}
								>
									<div
										id="headingTwo"
										className="card-header bg-primary shadow-sm border-0 rounded py-2"
									>
										<h6 className="mb-0 font-weight-bold">
											<a
												href="#"
												data-toggle="collapse"
												data-target="#collapseFour"
												aria-expanded="false"
												aria-controls="collapseThree"
												className="d-block position-relative font-weight-bold collapsed text-white collapsible-link py-2"
											>
												El quiz
											</a>
										</h6>
									</div>
									<div
										id="collapseFour"
										aria-labelledby="headingTwo"
										data-parent="#accordionExample"
										className="collapse"
									>
										<div className="alert alert-dismissible alert-light mensajes-info">
											En cualquier momento mide tu
											aprendizaje.
										</div>
										<Examen idSesion={idSesion} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => ({
	recursos: state.sesiones.recursos,
	curso: state.cursos.curso,
	sesion: state.sesiones.sesion,
	tareas: state.sesiones.tareas,
	detalleNivel: state.usuario.detalleNivel,
	usuario: state.usuario.usuario,
	videos: state.sesiones.videos,
	podcasts: state.sesiones.podcasts,
	linkVivo: state.sesiones.linkVivo,
});

export default connect(mapStateToProps, {
	listarRecursos,
	listarSesion,
	listarTareas,
	listarCurso,
})(ContenidoSesiones);
