import React from "react";
import "./Certificado.scss";
import AvisoCertificado from "../../../Aviso/AvisoCertificado";

//redux
import { useSelector } from 'react-redux';
import { MensajeHead, ContainerDocumentos } from "../Styles";

const CertificadoContainer = ({idProyecto}) => {

  const certificado = useSelector(state => state.cursos.certificado)
  const idMatricula = useSelector(state => state.cursos.idMatricula) 

  return ( 
    <div className="certificado shadow-sm m-1 p-3">
     
      <div className="content-header header-vista-content py-1">
        <h4 className="mb-0 title text-uppercase font-20">diploma/certificado</h4>
      </div>

      {!certificado ? (
        <AvisoCertificado />
      ) : (
        <>
        <ContainerDocumentos>
          <div className="mensaje-aprovacion-certificado">
            <MensajeHead>¡Aprobaste! &#128526;</MensajeHead>
            <div className="mensaje d-flex">
              <p className="mb-0">
                Felicidades, ya puedes acceder a tu
               { parseInt(idProyecto)<31 ? <a
                  href={`${process.env.REACT_APP_API_URL}/admin/certificado/generarCertificado__IdMatricula/${idMatricula}`}
                  rel="noopener noreferrer"
                  className='ml-1'
                >
                  certificado digital
                </a>:<a
                  href={`${process.env.REACT_APP_API_URL}/files_inedi/certificados_firmados/${certificado[0].archivo}`}
                  rel="noopener noreferrer"
                  className='ml-1'
                >
                  certificado digital
                </a>}
              </p>
              {/* <PDFDownloadLink
                document={<Certificado certificado={certificado} />}
                fileName="certificado.pdf"
                className='text-success'
              >
                {({ blob, url, loading, error }) =>
                  loading ? "Cargando certificado..." : "certificado digital"
                }
              </PDFDownloadLink> */}
            </div>
          </div>
        </ContainerDocumentos>
        </>
      )}
    </div>
   );
}
 
export default CertificadoContainer;