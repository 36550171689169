import React from "react";
import { Table } from "react-bootstrap";
import * as moment from "moment";
//Redux
import { connect } from "react-redux";
import rankIcon from "../../../static/icons/tag-icon.png";
import "./TablaCalificaciones.scss";
import { useParams } from "react-router-dom";

const TablaCalificaciones = (props) => {
	const { calificaciones } = props;

	return (
		<div className="tabla-calificaciones table-responsive">
			<Table className="tabla">
				<thead>
					<tr>
						<th>Módulo</th>
						<th>Sesión</th>
						<th>Fecha</th>
						<th>Nota/Quiz</th>
						<th>
							<img src={rankIcon} style={{ maxWidth: "30px" }} />
						</th>
						<th>Asistencia</th>
					</tr>
				</thead>
				<FilasTabla calificaciones={calificaciones} />
			</Table>
		</div>
	);
};

const mapStateToProps = (state) => ({
	calificaciones: state.cursos.calificaciones,
});

export default connect(mapStateToProps, {})(TablaCalificaciones);

const FilasTabla = (props) => {
	const { calificaciones } = props;
	const { idCapacitacion } = useParams();
	return (
		<tbody>
			{calificaciones.detalles &&
				calificaciones.detalles.map((fila, index) => (
					<React.Fragment key={index}>
						{fila.children.map((sesion, index) => (
							<React.Fragment key={index}>
								{index < 1 ? (
									<tr key={fila.id}>
										<td
											rowSpan={fila.children.length}
											title={fila.nombre}
											className="td-modulo position-relative"
										>
											<div className="content-nombre-modulo w-100 h-100 position-absolute d-flex align-items-center font-weight-bold">
												<p>
													{fila.nombre.length > 50
														? `${fila.nombre.substr(
																0,
																50
														  )}...`
														: fila.nombre}
												</p>
											</div>
										</td>
										<td
											className="td-nombre-sesion"
											title={sesion.nombre}
										>
											{sesion.nombre.length > 50
												? `${sesion.nombre.substr(
														0,
														50
												  )}...`
												: sesion.nombre}
										</td>
										<td
											className={
												sesion.nombre.includes(
													"modular grupal"
												) ||
												sesion.nombre.includes(
													"Evaluación grupal"
												) ||
												sesion.nombre.includes(
													"Trabajo Aplicativo"
												)
													? "font-weight-bold"
													: ""
											}
										>
											{moment(sesion.fecha).format(
												"DD/MM/YYYY"
											)}
										</td>
										<td className="text-center">
											{parseInt(sesion.nota)}
										</td>
										<td className="text-center">
											{sesion.nombre_evaluacion ===
											"Examen sesión"
												? parseInt(sesion.puntos)
												: "---"}
										</td>
										<td>{sesion.asistencia}</td>
									</tr>
								) : (
									<tr key={fila.id}>
										<td title={sesion.nombre}>
											{sesion.nombre.length > 50
												? `${sesion.nombre.substr(
														0,
														50
												  )}...`
												: sesion.nombre}
										</td>
										<td
											className={
												sesion.nombre.includes(
													"modular grupal"
												) ||
												sesion.nombre.includes(
													"Evaluación grupal"
												) ||
												sesion.nombre.includes(
													"Trabajo Aplicativo"
												)
													? "font-weight-bold"
													: ""
											}
										>
											{moment(sesion.fecha).format(
												"DD/MM/YYYY"
											)}
										</td>
										<td className="text-center">
											{parseInt(sesion.nota)}
										</td>
										<td className="text-center">
											{sesion.nombre_evaluacion ===
											"Examen sesión"
												? parseInt(sesion.puntos)
												: "---"}
										</td>
										<td>{sesion.asistencia}</td>
									</tr>
								)}
							</React.Fragment>
						))}
					</React.Fragment>
				))}
			{(parseInt(idCapacitacion) >= 45 &&
				parseInt(idCapacitacion) !== 100) ||
			parseInt(idCapacitacion) === 31 ||
			parseInt(idCapacitacion) === 32 ||
			parseInt(idCapacitacion) === 35 ? (
				<tr>
					<td
						colSpan="3"
						style={styles.notaFinal}
						className="font-weight-bold text-right"
					>
						Promedio Modular
					</td>
					<td
						colSpan="3"
						style={styles.notaFinal}
						className="font-weight-bold"
					>
						{calificaciones.promedio_modular
							? parseInt(calificaciones.promedio_modular)
							: 0}
					</td>
				</tr>
			) : null}
			<tr>
				<td
					colSpan="3"
					style={styles.notaFinal}
					className="font-weight-bold text-right"
				>
					{(parseInt(idCapacitacion) >= 45 &&
						parseInt(idCapacitacion) !== 100) ||
					parseInt(idCapacitacion) === 31 ||
					parseInt(idCapacitacion) === 32 ||
					parseInt(idCapacitacion) === 35
						? "TAF"
						: "Evaluación Final"}
				</td>
				<td
					colSpan="3"
					style={styles.notaFinal}
					className="font-weight-bold"
				>
					{calificaciones.examen
						? parseInt(calificaciones.examen)
						: 0}
				</td>
			</tr>
			{(parseInt(idCapacitacion) >= 45 &&
				parseInt(idCapacitacion) !== 100) ||
			parseInt(idCapacitacion) === 31 ||
			parseInt(idCapacitacion) === 32 ||
			parseInt(idCapacitacion) === 35 ? (
				<tr>
					<td
						colSpan="3"
						style={styles.notaFinal}
						className="font-weight-bold text-right"
					>
						Promedio final
					</td>
					<td
						colSpan="3"
						style={styles.notaFinal}
						className="font-weight-bold"
					>
						{calificaciones.promedio
							? Math.round(calificaciones.promedio)
							: 0}
					</td>
				</tr>
			) : null}
		</tbody>
	);
};
const styles = {
	notaFinal: {
		fontSize: "14px",
	},
};
