import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import './CountDownTime2.css';
import {examenesTypes} from '../../../actions/types'
const CountDownTime = (props) => {
    const {tiempo} = props;
    const[timerMinutes,setTimerMinutes] = useState('00');
    const[timerSeconds, SetTimerSeconds] = useState('00');

    let interval = useRef();
    const dispatch=useDispatch();
    const empezarContador = (minutos) => {
      
        let finalTime=null;

        if(localStorage.getItem('TiempoFinal') !== undefined && localStorage.getItem("TiempoFinal"))
        {
             finalTime = localStorage.getItem("TiempoFinal");
        }
        else
        {   
            finalTime = new Date().getTime()+(minutos*60000);
            localStorage.setItem("TiempoFinal",finalTime);
        }

        interval = setInterval(() =>{

       
        const now = new Date().getTime();
        const currentTime =  finalTime - now;
    
 
        let mins = Math.floor((currentTime%(1000*60*60))/(1000*60));
        let secs = Math.floor((currentTime%(1000*60))/(1000));

      


        if(secs<0 && mins<0)
        {
            if(secs<10)
            secs='00';
            if(mins<10)
            mins='00'; 
            setTimerMinutes(mins);
            SetTimerSeconds(secs);
            localStorage.removeItem('TiempoFinal');
            //console.log('Terminó');
            clearInterval(interval);

            dispatch({
                type:examenesTypes.TIEMPO_EXAMEN_TERMINADO
            })
        }
        else
        {
           if(secs<10)
            secs='0'+secs;
           if(mins<10)
            mins='0'+mins; 
            setTimerMinutes(mins);
            SetTimerSeconds(secs);
            
        }

        },1000) ;

   
    }

    useEffect(()=>{
        if(tiempo)
        empezarContador(parseInt(tiempo));
        return () =>{
            clearInterval(interval);
        };
    },[tiempo])

    return (      
    <div className="count-container">
          <div className="time">
           Tiempo restante: {`${timerMinutes}:${timerSeconds}`}
          </div>
    </div>

    );
};

export default CountDownTime;