import React from 'react';
import * as moment from 'moment';
//REDUX
import { useHistory, useParams } from 'react-router-dom';

const styles = {
  colorIcon: {
    color: "#A32900",
  },
  tr:{
    cursor: "pointer",
    fontSize: "13px"
  }
};


const Sesion = ({ sesion, index}) => {

  const { idCurso } = useParams()
  const history = useHistory()

  const traerRecursos = () => {
    history.push(`/capacitaciones/${idCurso}/sesiones/${sesion.id_sesion}`)
  }

  return (
    <tr
      style={styles.tr}
      onClick={() => traerRecursos()}
      className='tr-sesion'
    >
      <th scope="row" className='text-center'>{index + 1}</th>
      <td >{sesion.nombre_sesion}</td>
      <td className='text-center text-capitalize'>{moment(sesion.fecha_sesion).format('DD MMM YYYY')}</td>
      <td style={styles.colorIcon} className="text-right">
        <button className='btn p-click'>
          Click aquí
        </button>
      </td>
    </tr>
  );
};

 
export default Sesion;