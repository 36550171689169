import React from "react";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import Layout from "../Layout/Layout";
import TitlePagina from "../TitlePagina";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import "./changePass.scss";
//REDUX
import { connect } from "react-redux";
import { editarPassword } from "../../actions/usuarioActions";


const ChangePass = ({ usuario, editarPassword }) => {

  //Usando Formik para validar contraseña
  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      new_password_repeat: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required("Este campo es obligatorio"),
      new_password: Yup.string()
        .required("Este campo es obligatorio")
        .min(6, "Debe de tener como mínimo 6 caracteres"),
      new_password_repeat: Yup.string()
        .required("Este campo es obligatorio")
        .min(6, "Debe de tener como mínimo 6 caracteres")
    }),
    onSubmit: async valores => {
    
      const {old_password, new_password, new_password_repeat} = valores
      //veridicacion de password nuevo
      if (new_password !== new_password_repeat) {
        
        formik.errors.new_password = 'Contraseña no coincide'
        formik.errors.new_password_repeat = 'Contraseña no coincide'
        return
      }
      //generando data
      const dataPass = {
        old_password: old_password,
        new_password: new_password,
      };
      //Guardando la nueva contraseña
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "¿Estas seguro?",
          text: "Ya no se podrá recuperar su contraseña",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async function () {
            //consultando para ver el mensaje de aprovación
            if (await editarPassword(usuario.id_usuario, dataPass)) {
              return true;
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "La contraseña actual ingresada es errónea",
              });
            }
          },
          allowOutsideClick: () => !Swal.isLoading(),
        })
        .then((result) => {
          //mensaje que se cambio la contraseña satisfactoriamente
          if (result.value === true) {
            swalWithBootstrapButtons.fire(
              "Cambiado!",
              "Contraseña se cambio correctamente",
              "success"
            );
          }
        });
      }
  });

  
  return (
    <Layout>
      <div
        className="container cambiar-password bg-white shadow-sm contenedor-change-pass py-2 px-3 mt-5"
        style={{ minHeight: "100%" }}
      >
        <TitlePagina clases='primary'>
          cambio de contraseña
        </TitlePagina>
        <form onSubmit={formik.handleSubmit} className="mt-5">
          <div className="form-row mb-4">
            <div className="col-md-3">
              <label htmlFor="usuario">Usuario:</label>
            </div>
            <div className="col-md-4">
              <input
                type="text"
                id="usuario"
                disabled
                defaultValue={usuario.usuario}
                className="form-control"
              />
            </div>
            <div className="col-md-5"></div>
          </div>
          <div className="form-row mb-4">
            <div className="col-md-3">
              <label htmlFor="old_password">Contraseña actual:</label>
            </div>
            <div className="col-md-4">
              <input
                type="password"
                name="old_password"
                className="form-control"
                id="old_password"
                required
                value={formik.values.old_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.old_password && formik.errors.old_password ? (
                <small id="emailHelp" className="form-text text-danger">
                  {formik.errors.old_password}
                </small>
              ) : null}
            </div>
          </div>
          <div className="form-row mb-4">
            <div className="col-md-3">
              <label htmlFor="new_password">Nueva contraseña:</label>
            </div>
            <div className="col-md-4">
              <input
                type="password"
                name="new_password"
                id="new_password"
                className="form-control"
                required
                value={formik.values.new_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.new_password && formik.errors.new_password ? (
                <small id="emailHelp" className="form-text text-danger">
                  {formik.errors.new_password}
                </small>
              ) : null}
              
            </div>
          </div>
          <div className="form-row mb-4">
            <div className="col-md-3">
              <label htmlFor="new_password_repeat">Repetir contraseña</label>
            </div>
            <div className="col-md-4">
              <input
                type="password"
                name="new_password_repeat"
                id="new_password_repeat"
                className="form-control"
                required
                value={formik.values.new_password_repeat}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.new_password_repeat && formik.errors.new_password_repeat ? (
                <small id="emailHelp" className="form-text text-danger">
                  {formik.errors.new_password_repeat}
                </small>
              ) : null}
              
            </div>
          </div>
          <div className="row">
            

            <div className="col-md-3"></div>
            <div className="col-md-4 px-2">
              <button type="submit" className="btn text-white btn-guardar">
                <FontAwesomeIcon className='mr-2' icon={faPencilAlt} />
                Guardar configuración
              </button>
            </div>
            <div className="div-md-5"></div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  usuario: state.usuario.usuario,
});

export default connect(mapStateToProps, { editarPassword })(ChangePass);
