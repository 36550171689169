import React from 'react';
import { useHistory } from 'react-router-dom';
import { getJWT } from '../../components/Helpers/FunctionsHelpers';
import { Container, Image } from 'react-bootstrap';
import FormLogin from '../../components/auth/FormLogin';
import BackgroundSlider from 'react-background-slider'

import './Login.scss';

const Login = () => {

  const history = useHistory()

  const jwtUsuario = getJWT();
  if (jwtUsuario !== null)  history.push('/capacitaciones')

  
  const images = [
    '/images/slider/banner_1.jpg',
    '/images/slider/banner_2.jpg',
    '/images/slider/banner_3.jpg'
  ]

  return ( 
    <Container fluid className="Login px-0">
      <div className="row Login__container">
        <div className="col-xs-12 col-lg-4 Login__left">
          <div>
            <div className=''>
            <Image src={'/images/gestora_morado.png'} style={{marginLeft:'5px'}}/>
            <h4 className='text-primary'>intranet del estudiante</h4>
            </div>
            <div>
              <FormLogin />
            </div>
          </div>
          <div className='Login__sociales text-center'>
            <p className='Login__sociales__mensaje'>Síguenos en nuestras redes sociales</p>
            <div className='Login__sociales__imgs d-flex justify-content-center'>
              <div className='img-sociales-content'>
                <a href="https://www.facebook.com/inediposgrados/" target='_blank' rel="noopener noreferrer">
                  <img src="/images/login-sociales/facebook.png" alt=""/>
                </a>
              </div>
              <div className='img-sociales-content'>
                <a href="https://www.linkedin.com/company/inediperu/" target='_blank' rel="noopener noreferrer">
                  <img src="/images/login-sociales/linkedin.png" alt=""/>
                </a>
              </div>
              <div className='img-sociales-content'>
                <a href="https://open.spotify.com/show/0fAJ0Av3csAPLSmlSdzlin" target='_blank' rel="noopener noreferrer">
                  <img src="/images/login-sociales/spotify.png" alt=""/>
                </a>
              </div>
              <div className='img-sociales-content'>
                <a href="https://www.youtube.com/channel/UCs7gtHRPuTAY8fiQkaJrqVw" target='_blank' rel="noopener noreferrer">
                  <img src="/images/login-sociales/youtube.png" alt=""/>
                </a>
              </div>
              <div className='img-sociales-content'>
                <a href="https://www.instagram.com/inediposgrados/" target='_blank' rel="noopener noreferrer">
                  <img src="/images/login-sociales/insta.png" alt=""/>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-lg-8 Login__rigth px-0">
          <BackgroundSlider
            images={images}
            duration={8} 
            transition={.5}
          />
        </div>
      </div>
    </Container>
   );
}
 
export default Login;
